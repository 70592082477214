//INFO: 갤러리 등록, 보기, 수정
<script>
import LoadingVue from '@/components/ui/Loading.vue'
import TextEditor from '@/components/TextEditor.vue' // TextEditor 불러오기
import { getGallery, insertGallery, updateGallery } from '@/api/gallery'

export default {
  name: 'adminBoardGalleryCreate',
  components: { TextEditor, LoadingVue },
  data() {
    const boardNo = this.$route.params.boardNo
    const boardData = {
      boardName: 'gallery',
      boardId: boardNo
    }

    return {
      boardNo,
      boardData,
      galleryData: {},
      isLoading: false
    }
  },
  setup() {},
  created() {},
  async mounted() {
    this.galleryData = await getGallery(this.boardNo)
  },
  unmounted() {},
  methods: {
    async update(data, boardNo) {
      const obj = {
        title: data.title,
        content: await data.getHtmlAfterSaveImages(this.boardData)
      }

      return await updateGallery(obj, boardNo)
    },

    async insert(data) {
      const result = await insertGallery({ title: data.title, content: ' ' })

      if (result?.code) return result

      this.boardData.boardId = result.galleryId

      const obj = {
        title: data.title,
        content: await data.getHtmlAfterSaveImages(this.boardData)
      }

      return await updateGallery(obj, result.galleryId)
    },

    async saveGallery({ getHtmlAfterSaveImages }) {
      this.isLoading = true

      const data = {
        title: this.galleryData.title,
        getHtmlAfterSaveImages
      }

      const result = this.boardNo ? await this.update(data, this.boardNo) : await this.insert(data)
      this.isLoading = false
      if (result?.code) return alert(result.message)

      alert('저장되었습니다.')
      this.goGalleryList()
    },

    goGalleryList() {
      // 목록 버튼
      this.$router.push({
        path: '/admin/board/gallery'
      })
    }
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading"></LoadingVue>
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h4>갤러리</h4>
  </nav>

  <div id="content-wrapper" class="d-flex flex-column">
    <div class="btnArea">
      <button class="e-btn wf__btn btn-primary" @click="goGalleryList()">목록</button>
      <button class="e-btn wf__btn btn-success ml-2" @click="$refs.editor.saveContent()">저장</button>
    </div>

    <div class="adminTable">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="90%" />
        </colgroup>

        <tbody>
          <tr>
            <th>제목</th>
            <td><input v-model="galleryData.title" placeholder="제목을 입력해주세요" size="50" required /></td>
          </tr>
          <tr>
            <th></th>
            <td>
              <text-editor
                class=""
                v-if="!boardNo || galleryData.content"
                height="50vh"
                :propsData="galleryData.content"
                @emitData="saveGallery"
                :boardData="boardData"
                :hideSave="true"
                ref="editor"
              ></text-editor>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
#content-wrapper {
  max-width: var(--wf-container-width);
}

.btnArea {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}
</style>
